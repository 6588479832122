import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import picSecondary from '../assets/images/pic-secondary.jpg'
import Banner from '../components/Banner'
import Layout from '../components/layout'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="RED Ibis Computing"
          meta={[
            {
              name: 'description',
              content:
                'Web development, cloud solutions, data management, and process automation for small business',
            },
            {
              name: 'keywords',
              content:
                'automation, cloud, cloud management, cloud solution, data management, small business, software, software solution, web, web development',
            },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${picSecondary})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/"
                className="link primary"
              >
                <header className="major">
                  <h3>Web Development</h3>
                  <p>Public Web Applications | Private Intranet Portals</p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${picSecondary})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/"
                className="link primary"
              >
                <header className="major">
                  <h3>Cloud-Based Solutions</h3>
                  <p>Software Modernization Transitioned Off-Premise</p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${picSecondary})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/"
                className="link primary"
              >
                <header className="major">
                  <h3>Data Processing</h3>
                  <p>Extraction | Transformation | Loading | Retrieval</p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${picSecondary})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/"
                className="link primary"
              >
                <header className="major">
                  <h3>Workflow Automation</h3>
                  <p>Business Process Management</p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${picSecondary})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/"
                className="link primary"
              >
                <header className="major">
                  <h3>Small-Business Focused</h3>
                  <p>
                    Enterprise solutions designed for operations of all sizes
                  </p>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${picSecondary})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/"
                className="link primary"
              >
                <header className="major">
                  <h3>Complete Lifecycle Support</h3>
                  <p>From Planning to Maintenance</p>
                </header>
              </Link>
            </article>
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>Let Us Know What You Need</h2>
              </header>
              <p>
                With 10+ years of experience in software engineering, RED Ibis
                Computing aims to provide professional services to take your
                business to the next level.
              </p>
              {/*
              <ul className="actions">
                <li>
                  <Link
                    aria-label="Link to Landing Page"
                    to="/landing"
                    className="button next"
                  >
                    Get Started
                  </Link>
                </li>
              </ul>
              */}
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
